import React, { memo, useState, useRef } from 'react';
import { Position } from 'reactflow';
import Handle from '../../../components/ProcessEditor/Handle'
import { generate, green, presetPalettes, red } from '@ant-design/colors';

import { Select, Row, Col, Space, Divider, Form, ColorPicker, Card, Input, Modal, Checkbox, Button, InputNumber, theme  } from 'antd';
import useAsyncEffect from 'use-async-effect';
import { Popover, Tooltip  } from 'antd';

import {
  EyeOutlined, EyeFilled, EyeTwoTone, BgColorsOutlined, PlusOutlined
} from '@ant-design/icons';
import { BsFiletypeJson } from "react-icons/bs";

import ModelModel from '../../../models/Model'
import sys from '../../../system'
import NodeCard from '../../../components/ProcessEditor/NodeCard'
import GeometryStylesForm from '../../../forms/GeometryStyles'
import GeometryStyle from '../../../models/GeometryStyle';

import CodeMirror from '@uiw/react-codemirror';
import { langs } from '@uiw/codemirror-extensions-langs';
import { vscodeDark, vscodeDarkInit } from '@uiw/codemirror-theme-vscode';


const colorPresets = [
  {
    "label": "RGB",
    "colors": [
      "#ff1478" + "20",
      "#ff1478" + "40",
      "#ff1478" + "60",
      "#ff1478" + "80",
      "#ff1478" + "A0",
      "#ff1478" + "C0",
      "#ff1478" + "E0",
      "#ff1478" + "FF",

      "#14ff78" + "20",
      "#14ff78" + "40",
      "#14ff78" + "60",
      "#14ff78" + "80",
      "#14ff78" + "A0",
      "#14ff78" + "C0",
      "#14ff78" + "E0",
      "#14ff78" + "FF",

      "#1478FF" + "20",
      "#1478FF" + "40",
      "#1478FF" + "60",
      "#1478FF" + "80",
      "#1478FF" + "A0",
      "#1478FF" + "C0",
      "#1478FF" + "E0",
      "#1478FF" + "FF",
    ]
  }, {
  "label": "Gray Scale",
    "colors": [
      "#000000" + "20",
      "#000000" + "40",
      "#000000" + "60",
      "#000000" + "80",
      "#000000" + "A0",
      "#000000" + "C0",
      "#000000" + "E0",
      "#000000" + "FF",
    ]
  }
]

const Component = props => {

  if (props.data.strokeColor == null) {
    props.data.strokeColor = '#0000ff80';
  }
  if (props.data.fillColor == null) {
    props.data.fillColor = '#0000ff1a';
  }
  if (props.data.style == undefined) {
    props.data.style = null;
  }

  if (props.data.active == null) {
    props.data.active = true
  }

  const [openPayloadModal, setOpenPayloadModal] = useState(false);
  const [name, setName] = useState(props.data.name);
  const [strokeColor, setStrokeColor] = useState(props.data.strokeColor);
  const [fillColor, setFillColor] = useState(props.data.fillColor);
  const [active, setActive] = useState(props.data.active);
  const [style, setStyle] = useState(props.data.style);
 
  return (
    <NodeCard
      {...props}
      handles={[
        <Handle
          key='1'
          name='activation'
          style={{ top: '40%' }}
          title="Activate"
          accepts={['any']}
          activation
        />,
        <Handle
          key='2'
          name='polyset'
          title="[Polyset, Polygon] a to be saved"
          style={{ top: '66%', }}
          accepts={['polyset', 'poly']}
          maxConnections={1}
        />,
        <Handle
        key='3'
        name='image'
          style={{ top: '85%', }}
          title="Associated [Image]"
          accepts={['image']}
          />,
        <Handle
          key='4'
          name='out'
          style={{ top: '50%' }}
          title='Produces the same [Polyset]'
          produces={['polyset']}
          activation
        />
      ]}
    >
      <Form style={{width: 260, padding: 16 }}>
          <div style={{display: 'flex', alignItems: 'baseline', justifyContent: 'space-around'}}>
            <div>
              <Form.Item label="Active" labelCol={{ span: 18 }}>
                <Checkbox
                  checked={active}
                  onChange={(ev) => {
                    props.data.active = ev.target.checked;
                    setActive(props.data.active);
                    props.onChange();
                  }}
                  />
              </Form.Item>
            </div>
            <div>
              <Button
                  size='large'
                  type={sys.isEmpty(style) ? "default" : "primary"}
                  onClick={() => {
                    setOpenPayloadModal(true);
                  }}
                  icon={<BsFiletypeJson/>}
                />
              </div>
            </div>
          <Form.Item label="Name" labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
            <Input
              value={name}
              onChange={ev => {
                props.data.name = ev.target.value;
                setName(props.data.name)
                props.onChange();
              }}
            />
          </Form.Item>
            <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
              <Form.Item label="Fill Color">
                <ColorPicker 
                  value={fillColor} 
                  allowClear
                  onChangeComplete={(color, hex) => {
                    props.data.fillColor = '#' + color.toHex();
                    setFillColor(props.data.fillColor);
                    props.onChange();
                  }}
                  presets={colorPresets}
                  />
              </Form.Item>
              <Form.Item label="Stroke Color">
                <ColorPicker
                  value={strokeColor} 
                  allowClear
                  onChangeComplete={(color, hex) => {
                    props.data.strokeColor = '#' + color.toHex();
                    setStrokeColor(props.data.strokeColor);
                    props.onChange();
                  }}
                  presets={colorPresets}
                />
              </Form.Item>
            </div>
      </Form>

      <Modal
        open={openPayloadModal}
        title="Edit Payload"
        width={'60vw'}
        centered
        onOk={() => {
          setOpenPayloadModal(false);
          props.data.style = style;
          setStyle(props.data.style)
          props.onChange();
        }}
        onCancel={() => {
          setOpenPayloadModal(false);
        }}
        destroyOnClose={true}
      >
        <CodeMirror
          extensions={[langs.python()]}
          value={style == null || typeof style === 'object' ? '' : style}
          theme={vscodeDarkInit({
            settings: {
              caret: '#c6c6c6',
              fontFamily: 'monospace',
            }
          })}
          onChange={(value) => {
            if (value != null && value.trim() === '') {
              value = null
            }
            setStyle(value)
          }}
          height={'800px'}
          onBlur={ev => {
            props.onChange();
          }}
          basicSetup={{
            foldGutter: true,
            allowMultipleSelections: false,
            autocompletion: false,
          }}
        />
      </Modal>
    </NodeCard>
  )
};

Component.displayName = 'SaveGeometryNode';
export default Component;

