import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Progress, Form, Skeleton, Checkbox, Steps, Collapse, Upload, Button, Radio, Tabs, Popconfirm, Modal, Space, Alert, Menu, Row, Col, Badge, Card, Spin, Select, Drawer, Tooltip, Divider    } from 'antd';
import dayjs from 'dayjs';

import { SearchOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import {  PlusCircleOutlined, CopyOutlined } from '@ant-design/icons';
import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
  useParams,
  useLocation,
  matchRoutes,
  matchPath
} from "react-router-dom";
import ModelModel from '../../models/Model'

import { TbReplace as BatchIcon } from "react-icons/tb";

import MyInput from '../../components/Input';
import { useStore } from '../../store';
import { FilterFilled, ClearOutlined, SettingOutlined,EllipsisOutlined  } from '@ant-design/icons';
import { FaRunning } from "react-icons/fa";
import PageHeader from '../../components/PageHeader'
import { SaveOutlined, EditOutlined, DeleteOutlined, SyncOutlined as RefreshIcon, 
  CloudDownloadOutlined as ImportIcon, 
  CloudUploadOutlined as ExportIcon  
} from '@ant-design/icons';
import { BsFiletypeJson } from "react-icons/bs";
import { TbTimelineEventExclamation } from "react-icons/tb";

import Table from '../../components/Table';
import Uuid from '../../components/Uuid';

import BackButton from '../../components/AppHeader/BackButton'

import Timeline from '../../components/ProcessEditor/Timeline'

import sys from '../../system'

import _, { set } from 'lodash'

import './index.less'

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Panel,
  ReactFlowProvider,
  useReactFlow,
  useStoreApi,
} from 'reactflow';
import 'reactflow/dist/base.css';

import { Handle, Position } from 'reactflow';
import RunForm from './../../forms/Run'
import ProcessCommitsForm from './../../forms/ProcessCommits'
// nodes 
import StartNode from './nodes/StartNode';
import CaptureFromSource from './nodes/CaptureFromSourceNode';
import DetectObjectNode from './nodes/DetectObjectNode';
import RegionTrapeziumNode from './nodes/RegionTrapeziumNode';
import RegionDefinedNode from './nodes/RegionDefinedNode';
import TriggerEventNode from './nodes/TriggerEventNode';
import ScriptNode from './nodes/ScriptNode';
import PolygonAddNode from './nodes/PolygonAddNode';
import PolygonSubtractNode from './nodes/PolygonSubtractNode';
import PolygonIntersectNode from './nodes/PolygonIntersectNode';
import PolygonExcludeNode from './nodes/PolygonExcludeNode';
import ExitNode from './nodes/ExitNode';
import PolygonContainsNode from './nodes/PolygonContainsNode';
import UngroupNode from './nodes/UngroupNode';
import FlagNode from './nodes/FlagNode';
import SaveGeometryNode from './nodes/SaveGeometryNode';
import JobScheduleNode from './nodes/JobScheduleNode';
import IfNode from './nodes/IfNode';
// edges
import FlowEdge from './edges/FlowEdge';
import DependencyEdge from './edges/DependencyEdge';


import ProcessModel from '../../models/Process';
import ProcessCommitModel from '../../models/Process_Commit';

import ImageModel from '../../models/Image';

import ProcessRunModel from '../../models/Process_Run';

const nodeTypes = [
  'Start',
  'DetectObject',
  'RegionTrapezium',
  'RegionDefined',
  'TriggerEvent',
  'CaptureFromSource',
  'Script',
  'PolygonAdd',
  'PolygonSubtract',
  'PolygonIntersect',
  'PolygonExclude',
  'Exit',
  'PolygonContains',
  'JobSchedule',
  'Ungroup',
  'Flag',
  'Geometry',
  'If',
]

const replaceUUIds = (sp) => {
  const uuids = {}

  sp = _.cloneDeep(sp);

  sp.id = uuids[sp.id] = sys.uuid();

  sp.nodes.forEach(n => {
    n.id = uuids[n.id] = sys.uuid();
  })

  sp.edges.forEach(n => {
    n.id = uuids[n.id] = sys.uuid();
  })

  sp.edges.forEach(e => {
    if (uuids[e.source] != null) {
      e.source = uuids[e.source];
    }
    if (uuids[e.target] != null) {
      e.target = uuids[e.target];
    }
  })

  const dfs = (obj, key, value) => {
    if (key == undefined && value == undefined) {
      dfs(null, null, obj)
      return
    } else if (value == null) {
      return;
    } else if (Array.isArray(value)) {
      value.forEach((v, k) => {
        dfs(value, k, v)
      })
    } else if (typeof value === 'object') {
      Object.entries(value).forEach(([k, v]) => {
        dfs(value, k, v)
      })
    } else {
      if (typeof value === 'string' && uuids[value] !== undefined) {
        obj[key] = uuids[value];
      }
    }
  }

  dfs(sp)


  return sp;
}


const flowToJson = (_state) => {
  const state = {
    updatedAt: _state.updatedAt,
    subprocess: [],
    vars: _state.vars,
    execution: _state.execution
  }

  for (const _subprocess of _state.subprocess) {
    // 
    const subprocess = {
      id: _subprocess.id,
      name: _subprocess.name,
      nodes: [],
      edges: [],
      viewport: _subprocess.viewport
    }

    // nodes
    for (const _node of _subprocess.nodes) {
      if (nodeTypes.indexOf(_node.type) === -1) {
        continue
      }
      const node = {
        ..._node,
        selected: undefined,
        dragging: undefined,
        positionAbsolute: undefined,
        width: undefined,
        height: undefined,
      }
      subprocess.nodes.push(node);
    }

    // eges
    for (const _edge of _subprocess.edges) {
      const edge = {
        ..._edge,
        selected: undefined,
      }
      subprocess.edges.push(edge);
    }

    state.subprocess.push(subprocess);
  }
  return state;
}

const jsonToFlow = (state) => {
  const _state = {
    updatedAt: state.updatedAt,
    subprocess: [],
    vars: state.vars,
    execution: state.execution
  }

  for (const subprocess of state.subprocess) {
    // 
    const _subprocess = {
      id: subprocess.id,
      name: subprocess.name,
      nodes: [],
      edges: [],
      viewport: subprocess.viewport
    }

    // nodes
    for (const node of subprocess.nodes) {
      const _node = {
        ...node,
        data: { 
          ...node.data
        }
      }
      _subprocess.nodes.push(_node);
    }

    // eges
    for (const edge of subprocess.edges) {
      const _edge = {
        ...edge
      }
      _subprocess.edges.push(_edge);
    }

    _state.subprocess.push(_subprocess);
  }
  return _state;
}

  
const newSubProcessData = (processName) => ({
  id: sys.uuid(),
  name: processName || 'New Process',
  nodes: [{ 
    id: sys.uuid(),
    type:"Start",
    width :142,
    height: 49,
    data: {
      title: "Start"
    },
    position: {
      x: 0, 
      y: 0,
    },
    positionAbsolute: {
      x: 0,
      y: 0
    },
    selected :true,
    dragging :false
  }],
  edges: [],
  viewport: {
    x: 200,
    y: 200,
    zoom: 1.0
  }
});


const DragableNewNode = (props) => {

  return (
    <div
      className='react-flow__nodes'
      onDragStart={props.onDragStart}
      draggable
      style={{ pointerEvents: 'all' }}
    >
      <Collapse 
        collapsible='icon'
        activeKey={[]}
        open={true}
        items={[
            {
              key: '1',
              label:
                <div style={{ cursor: 'grab' }} >
                {props.children}
                </div>
              }
            ]}      
            >
      </Collapse>
    </div>
  )
}


const BatchModal = (props) => {
  const [models, setModels] = useState([]);
  const [detectionModels, setDetectionModels] = useState(null);
  useAsyncEffect(async () => {
    if (props.openBatchModal == false) {
      return
    }
    const models = await ModelModel.select("status = 'ACTIVE'");
    setModels(models);

    const detectionModels = {}
    props?.process?._state?.subprocess?.forEach(sp => {
      sp?.nodes?.forEach(n => {
        if (n?.type === 'DetectObject') {
          console.log('ai', n)
          detectionModels[sp.name] = detectionModels[sp.name] || []
          detectionModels[sp.name].push(n)
        }
      })
    });
    setDetectionModels(detectionModels)
  }, [props.openBatchModal]);

  

  return (
    <Modal
      title="Batch Changes"
      open={props.openBatchModal} 
      destroyOnClose={true}
      cancelText="Close"
      width={'800px'}
      cancelButtonProps={{ style:{display: 'none'}}}
      onCancel={() => {
        if (typeof props.onCancel === 'function') {
          props.onCancel();
        }
      }}
      onOk={() => {
        if (typeof props.onOk === 'function') {
          props.onOk();
        }
      }}
    >
      <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10}}>
        {
          detectionModels && Object.entries(detectionModels).map(([k, n]) => {
            return (
                <Card key={k} title={k} size='small'>
                  { 
                    n.map((n, i) => {
                      return (
                        <Select
                          style={{padding: 2}}
                          key={`DetectObject-${i}`}
                          allowClear={true}
                          placeholder="Please select a Model"
                          value={n.data.modelId}
                          onSelect={modelId => {
                            n.data.modelId = modelId;
                            setDetectionModels({...detectionModels})
                            props.onChange()
                          }}
                          onClear={() => {
                            n.data.modelId = modelId;
                            setDetectionModels({...detectionModels})
                            props.onChange()
                          }}
                          options={models?.map(e => ({value: e.id, label: e.name}))}
                        />
                      )
                    })
                  }
              </Card>
            )
          })
        }
      </div>
  </Modal>
  )
};


const Flow = (props) => {
  const { zoomIn, zoomOut, setCenter } = useReactFlow();
  const flowStore = useStoreApi();

  const process = props.process;
  const subprocess = props.subprocess;
  const changes = useRef(0);

  const [openNodesDrawer, setOpenNodesDrawer] = useState(false);
  const [reactFlow, setReactFlow] = useState();
  const reactFlowWrapper = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodeTypes, setNodeTypes] = useState();
  const [edgeTypes, setEdgeTypes] = useState();
  
  const onChange = (eventName) => {
    changes.current++;
    if (changes.current === 1) {
      return;
    }
    if (typeof props.onChange === 'function') {
      return props.onChange();
    }
  }


  const nodeActions = {
    onChange,
    duplicateNode: (nodeId) => {
      setNodes((nds) => {
        let nd = nds.find(n => n.id === nodeId);
        const newNode = {
          id: sys.uuid(),
          type: nd.type,
          position: {
            x: nd.position.x + 100,
            y: nd.position.y + 100,
          },
          data: _.cloneDeep(nd.data)
        };
        return nds.concat(newNode);
      });
    },
    deleteNode: (nodeId) => {
      setNodes((nds) => nds.filter(n => n.id !== nodeId));
    }
  }

  const centerNode = nodeId => {
    if (nodeId == null) {
      return
    }
    
    const { nodeInternals } = flowStore.getState();
    const node = nodeInternals.get(nodeId)
    if (node != null) {
      const x = node.position.x + node.width / 2;
      const y = node.position.y + node.height / 2;
      const zoom = 1;
      setCenter(x, y, { zoom, duration: 1000 });
    }
  }

  useAsyncEffect(async () => {
    centerNode(props.selectedNodeId);
  }, [props.selectedNodeId])

  
  useAsyncEffect(async () => {
    if (subprocess == null) {
      return;
    }
    setNodes(subprocess.nodes);
    setEdges(subprocess.edges);
    setNodeTypes({
      Start: (props) => <StartNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      CaptureFromSource: (props) => <CaptureFromSource {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      DetectObject: (props) => <DetectObjectNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      RegionTrapezium: (props) => <RegionTrapeziumNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      RegionDefined: (props) => <RegionDefinedNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      TriggerEvent: (props) => <TriggerEventNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      Script: (props) => <ScriptNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      PolygonAdd: (props) => <PolygonAddNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      PolygonSubtract: (props) => <PolygonSubtractNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      PolygonIntersect: (props) => <PolygonIntersectNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      PolygonExclude: (props) => <PolygonExcludeNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      Exit: (props) => <ExitNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      PolygonContains: (props) => <PolygonContainsNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      Ungroup: (props) => <UngroupNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      Flag: (props) => <FlagNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      Geometry: (props) => <SaveGeometryNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      JobSchedule: (props) => <JobScheduleNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
      If: (props) => <IfNode {...props} {...nodeActions} process={process} subprocess={subprocess}/>,
    });
    setEdgeTypes({
      FlowEdge: (props) => <FlowEdge {...props} />,
      DependencyEdge: (props) => <DependencyEdge {...props} />,
    })
    changes.current = 0;


    

  }, [props.subprocess, props.update]);




  const onConnect = useCallback(
    (edge) => {
      edge.id = sys.uuid();
      console.log('EDGE', edge)
      if (edge.sourceHandle.endsWith('+') && edge.targetHandle.endsWith('+')) {
        edge.type = 'FlowEdge'
      } else {
        edge.type = 'DependencyEdge'
      }
      setEdges((edges) => addEdge(edge, edges))
    },
    []
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);



  const onDrop = useCallback(
    event => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const payload = event.dataTransfer.getData('application/reactflow');
      const { type, data } = JSON.parse(payload);
      
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlow.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: sys.uuid(),
        type,
        position,
        data
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlow]
  );
  const onDragStart = (event, payload) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(payload));
    event.dataTransfer.effectAllowed = 'move';
  };


  if (subprocess == null) {
    return;
  }
  return (
    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
      <ReactFlow
        onInit={(reactFlow) => {
          setReactFlow(reactFlow);
          if (typeof props.onInit === 'function') {
            props.onInit(reactFlow);
          }
          if (subprocess.viewport) {
            reactFlow.setViewport(subprocess.viewport);
          }
          centerNode(props.selectedNodeId)
        }}
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodesChange={(nodeChanges) => {
          onNodesChange(nodeChanges);
          onChange('onNodesChange');
        }}
        onEdgesChange={(edgeChanges) => {
          onEdgesChange(edgeChanges);
          onChange('onEdgesChange');
        }}
        onConnect={(connection) => {
          onConnect(connection);
          onChange('onConnect');
        }}
        minZoom={0.1}
        maxZoom={1}
        onDrop={onDrop}
        onDragOver={onDragOver}
        defaultViewport={subprocess.viewport}
        deleteKeyCode={['Delete']}
        attributionPosition="top-right"
      >
        <MiniMap/>
        <Controls position='top-left' showInteractive={!props.readOnly}></Controls>
        
        {
          props.readOnly == false
          ? <div>
            <Panel position='top-right'>
              <Button type='default' size='large' shape='circle' className='nodrag' onClick={() => {setOpenNodesDrawer(!openNodesDrawer)}} icon={<PlusCircleOutlined />}/>
            </Panel>
            <Drawer
              title={'Nodes'}
              placement="right"
              onClose={() => {
                setOpenNodesDrawer(!openNodesDrawer);
              }}
              open={openNodesDrawer}
              getContainer={false}
              mask={false}
              footer={
                <div style={{display: 'flex', flexDirection: 'column', gap: 10,}}>
                <Typography.Text>Filter nodes that Accept</Typography.Text>
                <Select
                  size='large'
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Anything"
                  options={[
                    {
                      key: '1',
                      label: 'Region',
                      value: 'region'
                    }, {
                      key: '2',
                      label: 'Image',
                      value: 'image'
                    }, {
                      key: '3',
                      label: 'Activation',
                      value: 'activation'
                    }
                  ]}
                  optionLabelProp="label"
                  allowClear
                >
                </Select>
                <Typography.Text>and Produce</Typography.Text>
                <Select
                  size='large'
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Anything"
                  options={[
                    {
                      key: '1',
                      label: 'Region',
                      value: 'region'
                    }, {
                      key: '2',
                      label: 'Image',
                      value: 'image'
                    }, {
                      key: '3',
                      label: 'Activation',
                      value: 'activation'
                    }
                  ]}
                  allowClear
                >
                  </Select>
              </div>
              }
            >
        
              <Row gutter={[16, 16]} >

                <Space direction="vertical" size={'large'} wrap={true}>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'Start', data: { title: 'Start' } })}>Start</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'DetectObject', data: { title: 'Detect Object'} }) }>Detect Object</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'RegionTrapezium', data: { title: 'Region Trapezium' } }) }>Trapezium Region</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'RegionDefined', data: { title: 'Defined Region' } }) }>Defined Region</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'TriggerEvent', data: { title: 'Trigger Event'} }) }>Trigger Event</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'CaptureFromSource', data: { title: 'Capture'} }) }>Capture</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'Script', data: { title: 'Script'} }) }>Script</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'PolygonAdd', data: { title: 'Add'} }) }>Polygon Add</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'PolygonSubtract', data: { title: 'Subtract'} }) }>Polygon Subtract</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'PolygonIntersect', data: { title: 'Intersect'} }) }>Polygon Intersect</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'PolygonExclude', data: { title: 'Exclude'} }) }>Polygon Exclude</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'Exit', data: { title: 'Exit'} }) }>Exit</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'PolygonContains', data: { title: 'Contains'} }) }>Polygon Contains</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'Ungroup', data: { title: 'Ungroup'} }) }>Ungroup</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'Flag', data: { title: 'Flag'} }) }>Flag</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'Geometry', data: { title: 'Save Geometry'} }) }>Save Geometry</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'JobSchedule', data: { title: 'Job Schedule'} }) }>Job Schedule</DragableNewNode>
                  <DragableNewNode onDragStart={event => onDragStart(event, { type: 'If', data: { title: 'If'} }) }>If</DragableNewNode>
                </Space>
              </Row>
            </Drawer>
           </div>
          : null
        }
      </ReactFlow>
    </div>
  );
}

const Component = (props) => {
  const params = useParams();
  const location = useLocation()


  const flow = useRef();
  const [update, setUpdate ] = useState(sys.uuid());
  const [toBeSaved, setToBeSaved] = useState(false);
  const [subprocess, setSubprocess] = useState();
  const [running, setRunning] = useState(false);
  const [undo, setUndo] = useState([]);
  const [process, setProcess] = useState();
  const [openRunModal, setOpenRunModal] = useState(false);
  const [openVariablesModal, setOpenVariablesModal] = useState(false);
  const [readOnly, setReadOnly] = useState(true)
  const [openTimelineDrawer, setOpenTimelineDrawer] = useState(false);
  const [commitCount, setCommitCount] = useState(0);
  const [selectedNodeId, setSelectedNodeId] = useState();
  const [openCommitsModal, setOpenCommitsModal] = useState(false);
  const [openBatchModal, setOpenBatchModal] = useState(false);

  const modalState = useRef();
  const runForm = useRef()

  const onRun = async () => {
    setRunning(true)
    setOpenRunModal(false)
    try {
      let runFormState;
      if (runForm.current != null) {
        await runForm.current.saveState();
        runFormState = await runForm.current.getState();
      } else {
        runFormState = localStorage.getItem('process_run_state');
        if (runFormState != null) {
          try {
            runFormState = JSON.parse(runFormState);
          } catch (e) {
            console.log(e)
          }
        } 
      }
      
      if (runFormState.workerId == null || runFormState.selected == null) {
        sys.notify({
          type: 'error',
          message: 'Required fields!'
        })
        setOpenRunModal(true);
        return;
      }

      const image = await ImageModel.get(runFormState.selected)
      const response = await sys.api(`worker/${runFormState.workerId}/run/${process.id}`, {
        method: 'POST',
        body: {
          capture: image.url,
          image_id: image.id,
          simulation_id: sys.uuid()
        }
      });
      const json = await response.json()
      
      if (response.ok) {
        sys.notify({
          type: 'success',
          message:
            <div>
              <div>
                Process Run 
                <Uuid value={json.process_run_id} link={`/processRun/${json.process_run_id}`}/>
              </div>
            </div>
        });
      } else {
        sys.notify({
          type: 'success',
          message:
            <div>
              <div>
                Process Run
              </div>
              <div>
                {json.message}
              </div>
              <Uuid value={json.process_run_id}></Uuid>
            </div>
        });
      }
    } catch (e) {
      sys.notify({
        type: 'error',
        message: 
          <div>
            <div>Error Running Process</div>
            <div>{e.message}</div>
          </div>
      });
      console.error(e)
    } finally {
      setRunning(false)
    }
  }

  useAsyncEffect(async () => {
    const processId = params.processId;
    const processRunId = params.processRunId;

    
    if (processRunId != null) {
      const process = await ProcessRunModel.get(processRunId);
      console.log('process', processRunId, process)
      process._state = jsonToFlow(process.draft_state)
      setProcess(process);
      setSubprocess(process?._state?.subprocess[0]);
      setReadOnly(true)
    } 

    if (processId != null) {
      let process = await ProcessModel.get(processId);
      if (process == null) {
        process = ProcessModel.create({
          id: processId,
          state: {
            subprocess: [newSubProcessData()],
            vars: null
          },
          draft_state: {
            subprocess: [newSubProcessData()],
            vars: null
          }
        });
        setToBeSaved(true);
      }
  
      process._state = jsonToFlow(process.draft_state)
      setProcess(process);
      setSubprocess(process?._state?.subprocess[0]);
      setReadOnly(false)

      const commitCount = await sys.orm('select count(*) from process_commit where process_id = $1', [process.id]);
      if (commitCount.length > 0) {
        setCommitCount(parseInt(commitCount[0].count));
      }
    }


  }, [props.processId]);

  const onSave = async () => {
    subprocess.nodes = flow.current.getNodes();
    subprocess.edges = flow.current.getEdges();
    subprocess.viewport = flow.current.getViewport();
    process._state.updatedAt = sys.now().toISOString();
    process.draft_state = flowToJson(process._state);
    await process.save();
    setToBeSaved(false);
  }
  const onCommit = async (message) => {
    if (_.isEmpty(message)){
      sys.notify({
        type: 'error',
        message: 'Message is required'
      });
      return;
    }
    process.draft_state = flowToJson(process._state);
    process.state = process.draft_state;
    const processCommit =  ProcessCommitModel.create({
      process_id: process.id,
      message: message,
      state: process.state,
    });
    const trx = sys.type.Transaction();
    trx.add(process);
    trx.add(processCommit);
    await trx.save();
    setCommitCount(commitCount + 1);
  }
  const onRefresh = async () => {
    const processId = params.processId;
    const process = await ProcessModel.get(processId);
    process._state = jsonToFlow(process.state)
    setSubprocess(process?._state?.subprocess.find(sp => sp.id === subprocess.id));
    setProcess(process);
    setToBeSaved(false)
  }

  return (
    <div style={{width:'100%', height: '100%'}}>

      <PageHeader>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', gap: 20}}>
          <BackButton/>


          <Tooltip title='Refresh' placement='bottom'>
            <Button
              type='text'
              disabled={!toBeSaved || readOnly}
              icon={<RefreshIcon />}
              onClick={onRefresh}
            />
          </Tooltip>

          <Tooltip title='Save' placement='bottom'>
            <Button
              type={toBeSaved && !readOnly ? 'default' : 'text' }
              danger={toBeSaved && !readOnly}
              disabled={!toBeSaved || readOnly}
              icon={<SaveOutlined />}
              onClick={async () => {
                await onSave()
              }}
            />
          </Tooltip>
          

          <Tooltip title='Variables' placement='bottom'>
            <Button
              type='text' 
              icon={<BsFiletypeJson  size={16} />}
              onClick={async () => {
                modalState.current = process._state.vars
                setOpenVariablesModal(true);
              }}
            />
          </Tooltip>

          <div>
            <Button.Group>
              <Tooltip title={<div>Run <kbd>CTRL + R</kbd></div>} placement='bottom'>
                <Button 
                  type='text' 
                  icon={<FaRunning size={16} />}
                  onClick={onRun}
                  loading={running}
                  disabled={toBeSaved || running || readOnly}
                />
              </Tooltip>
              <Button
                type='text' 
                icon={<EllipsisOutlined />}
                disabled={toBeSaved || running || readOnly}
                onClick={async () => {
                  setOpenRunModal(true);
                }}
              />
            </Button.Group>
          </div>

          <Typography.Title 
            style={{margin: 0}} 
            level={5} 
            editable={{
              autoSize: { maxRows: 1 },
              triggerType: 'text',
              onChange: (value) => {
                if (value === process.name) {
                  return;
                }  
                process.name = value;
                const cloned =  process.clone()
                cloned._state = process._state;
                setProcess(cloned)
                setToBeSaved(true);
              }
          }}
          >
            {process?.name}
          </Typography.Title>
          <Typography.Text 
            style={{margin: 0, minWidth: 300, flex: 'auto' }} 
            level={5} 
            editable={{
              autoSize: { maxRows: 1 },
              onChange: (value) => {
                if (value === process.description) {
                  return;
                }  
                process.description = value;
                const cloned =  process.clone()
                cloned._state = process._state;
                setProcess(cloned)
                setToBeSaved(true);
              }
          }}>{process?.description}</Typography.Text>
          <div>
            <Button
              icon={<BatchIcon />}
              disabled={running || readOnly}
              onClick={async () => {
                setOpenBatchModal(true);
              }}
            />
          </div>
          <div>
            <Button.Group size='small'>
              <Badge count={commitCount} size='small' showZero style={{zIndex: 1000}}>
                <Button 
                  danger
                  disabled={
                    toBeSaved 
                    || running 
                    || readOnly 
                    || process?.state?.updatedAt == process?.draft_state?.updatedAt
                  }
                  onClick={() => {
                    let message = null;
                    Modal.confirm({
                      title: 'Commit message',
                      onOk: () => { onCommit(message) },
                      content: (
                        <div>
                          <Input
                            onChange={e => {
                              message = e.target.value;
                            }}
                            required
                          ></Input>
                        </div>
                      ),
                      okText: "Commit!",
                      okType: 'danger',
                    });
                  }}>
                  Commit
                </Button>
              </Badge>
              <Button
                danger
                icon={<EllipsisOutlined />}
                disabled={toBeSaved || running || readOnly}
                onClick={async () => {
                  setOpenCommitsModal(true);
                }}
              />
            </Button.Group>
            
          </div>
          <div style={{width: 120, paddingTop: 5}}>
            <MyInput 
              model={process}
              column='status'
              onChange={(value) => {
                process.status = value;
                setToBeSaved(true);
              }}
            />
          </div>
        </div>
      </PageHeader>

      <ReactFlowProvider>
        <Card
          
          bodyStyle={{
            display: 'flex',
            flexDirection: 'row',
            width:'100%', 
            height: 'calc(100vh - 158px)', 
            padding: 1, 
            margin: 0,
          }}
          
          tabIndex={0}
          onTabChange={(key, event) => {
            const sp = process._state.subprocess.find(sp => sp.id === key);
            setSubprocess(sp);
          }}
          headStyle={{
            border: 'none',
          }}
          tabProps={{
            tabBarGutter: 50,
            activeKey: subprocess?.id,
            onTabClick: (key, event) => {
              console.log('tabProps.onTabClick', key, event);
            }
          }}
          tabBarExtraContent={{
              right: <Button.Group style={{display: 'flex', gap: 10}}>
                {/* SHIFT LEFT */}
                <Button
                  title="Shift Left"
                  type='text'
                  disabled={readOnly || process?._state?.subprocess?.findIndex(sp => sp.id === subprocess.id) == 0}
                  icon={<LeftOutlined />}
                  onClick={() => {
                    const index = process._state.subprocess.findIndex(sp => sp.id === subprocess.id);
                    if (index === 0) {
                      return;
                    }
                    process._state.subprocess.splice(index, 1);
                    process._state.subprocess.splice(index - 1, 0, subprocess);
                    process._state = {...process._state};
                    setToBeSaved(true);
                  }}
                />
                {/* NEW PROCESS */}
                <Button 
                  title="New Process"
                  type='text'
                  icon={<PlusOutlined />}
                  disabled={readOnly}
                  onClick={() => {
                    let newName = null;
                    Modal.confirm({
                      icon: <EditOutlined />,
                      title: 'Rename Process',
                      content: (
                        <div>
                          <Input
                            onChange={e => {
                              newName =  e.target.value;
                            }}
                            required
                          ></Input>
                        </div>
                      ),
                      onOk: () => {
                        if (_.isEmpty(newName)){
                          return;
                        }
                        const newSubProcess = newSubProcessData(newName);
                        process._state.subprocess.push(newSubProcess);
                        process._state = {...process._state};
                        setSubprocess(newSubProcess);
                        setToBeSaved(true);    
                      },
                      okText: 'OK',
                      cancelText: 'Cancel',
                    });              
                  }}
                />

                {/* RENAME PROCESS */}
                <Button 
                  type='text'
                  title="Rename Process"
                  icon={<EditOutlined />}
                  disabled={readOnly}
                  onClick={() => {
                    const oldName = subprocess.name;
                    let newName = subprocess.name;
                    Modal.confirm({
                      icon: <EditOutlined />,
                      title: 'Rename Process',
                      content: (
                        <div>
                          <Input
                            defaultValue={oldName}
                            onChange={e => {
                              newName =  e.target.value;
                            }}
                            ></Input>
                        </div>
                      ),
                      onOk: () => {
                        if (_.isEmpty(newName)){
                          return;
                        }
                        subprocess.name = newName;
                        setToBeSaved(true);
                      },
                      okText: 'OK',
                      cancelText: 'Cancel',
                    });
                  }}
                />

                {/* CLONE SUB PROCESS */}
                <Button 
                  type='text'
                  title="Duplicate Sub Process"
                  icon={<CopyOutlined />}
                  onClick={() => {
                    const newSp = replaceUUIds(subprocess);
                    process._state.subprocess.push(newSp);
                    console.log('newSP', newSp)
                    const sp = process._state.subprocess[process._state.subprocess.length - 1];
                    setSubprocess(sp);
                    setToBeSaved(true);
                  }}
                />

                {/* DELETE SUB PROCESS */}
                <Popconfirm
                  title="Delete"
                  description="Are you sure to delete this process?"
                  onConfirm={() => {
                    const index = process._state.subprocess.findIndex(sp => sp.id === subprocess.id);
                    if (index === process._state.subprocess.length) {
                      return;
                    }
                    process._state.subprocess.splice(index, 1);
                    let sp = process._state.subprocess[index - 1];
                    if (sp == null) {
                      sp = process._state.subprocess[0];
                    }
                    process._state = {...process._state};
                    setSubprocess(sp);
                    setToBeSaved(true);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    disabled={process?._state?.subprocess?.length <= 1}
                    type='text'
                    icon={<DeleteOutlined/>}
                  />
                </Popconfirm>

                <Upload
                  listType={null}
                  showUploadList={false}
                  accept="application/json"
                  action={(file) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                      let sp = JSON.parse(reader.result);
                      sp = replaceUUIds(sp);
                      process._state.subprocess.push(sp)
                      setSubprocess(process._state.subprocess[0])
                      setToBeSaved(true);
                    }
                    reader.readAsText(file)
                  }}
                  customRequest={() => {
                    console.log('upload')
                  }}
                >
                  <Button 
                    type='text'
                    title='Import Sub Process'
                    icon={<ExportIcon />}
                    disabled={readOnly}
                  />
                </Upload>

                <Button 
                  type='text'
                  title="Export Sub Process"
                  icon={<ImportIcon />}
                  onClick={() => {
                    const spState = subprocess
                    const json = JSON.stringify(spState, null, 2);
                    const binaryData = [];
                    binaryData.push(json);
                    sys.download(process.id + '.json', new Blob(binaryData, {type: "application/json"}))
                  }}
                />

                {/* SHIFT RIGHT */}
                <Button
                  title="Shift Right"
                  type='text'
                  disabled={readOnly || process?._state?.subprocess?.findIndex(sp => sp.id === subprocess.id) == process?._state?.subprocess?.length - 1}
                  icon={<RightOutlined />}
                  onClick={() => {
                    const index = process._state.subprocess.findIndex(sp => sp.id === subprocess.id);
                    if (index === process._state.subprocess.length - 1) {
                      return;
                    }
                    process._state.subprocess.splice(index, 1);
                    process._state.subprocess.splice(index + 1, 0, subprocess);
                    process._state = {...process._state};
                    setToBeSaved(true);
                  }}
                />
              </Button.Group>
          }}
          tabList={process?._state?.subprocess?.map(sp => {
            return ({
              key: sp.id,
              label: (
                <div>
                  <Typography.Text>
                    {sp.name}
                  </Typography.Text>
                </div>
              ),
            })
          })}
        >
          {
            readOnly ?
            <div style={{width: 360, paddingTop: 40, paddingLeft: 30, paddingRight: 10, paddingBottom: 10, overflow: 'auto' }}>
              <Timeline
                process={process} 
                subprocess={subprocess}
                onChange={(nodeId, subprocessId) => {
                  console.log('onNodeCHange', nodeId, subprocessId)
                  setSubprocess(process._state.subprocess.find(sp => sp.id === subprocessId));
                  setSelectedNodeId(nodeId);
                }}
              />
            </div>
            : null
          }
          {
            readOnly ?
            <Divider type="vertical" style={{height: '100%'}}/>
            : null
          }
          <Flow 
            key={subprocess?.id}
            update={update}
            onInit={reactFlow => {
              flow.current = reactFlow;
            }}
            process={process}
            subprocess={subprocess}
            selectedNodeId={selectedNodeId}
            onChange={(sp) => {
              subprocess.nodes = flow.current.getNodes();
              subprocess.edges = flow.current.getEdges();
              subprocess.viewport = flow.current.getViewport();
              process._state = { ...process._state };
              setToBeSaved(true);
            }}
            readOnly={readOnly}
          />
        </Card>
        
      </ReactFlowProvider>

      <Modal 
        title="Run" 
        open={openRunModal} 
        destroyOnClose={true}
        onOk={onRun}
        width={'400px'}
        onCancel={() => { 
          setOpenRunModal(false);
        }}
      >
        <RunForm ref={runForm}></RunForm>
      </Modal>

      <Modal
        title="Variables"
        open={openVariablesModal} 
        destroyOnClose={true}
        onOk={() => {
          setOpenVariablesModal(false);
          modalState.current = null;
          setToBeSaved(true)
        }}
        width={'600px'}
        onCancel={() => {
          process._state.vars = modalState.current;
          modalState.current = null;
          setOpenVariablesModal(false);
        }}
      >
        <MyInput
          title="Process Global Variables"
          type={sys.type.Json}
          value={modalState.current || ''}
          onChange={value => {
            process._state.vars = value;
          }}
          minHeight={'200px'}
        />
      </Modal>

      <Modal
        title="Process Commits"
        open={openCommitsModal} 
        destroyOnClose={true}
        cancelText="Close"
        width={'800px'}
        okButtonProps={{ style:{display: 'none'}}}
        onCancel={() => {
          setOpenCommitsModal(false);
        }}
      >
        <ProcessCommitsForm 
          process={process}
          processId={process?.id}
          onRevert={async (commit) => {
            const c = await ProcessCommitModel.get(commit.id);
            if (c == null) {
              sys.notify({
                type: 'error',
                message: 'Commit not found'
              })
              return;
            }
            process.draft_state = c.state;
            process._state = jsonToFlow(process.draft_state)
            setSubprocess(process?._state?.subprocess.find(sp => sp.id === subprocess.id));
            setProcess(process);
            setToBeSaved(true)
          }
        }
        >
        </ProcessCommitsForm>
      </Modal>

      <BatchModal  
        onChange={(sp) => {
          setToBeSaved(true);
        }}
        openBatchModal={openBatchModal}
        process={process}
        onOk={() => {
          setOpenBatchModal(false);
          setUpdate(sys.uuid());
        }}
        onCancel={() => {
          setOpenBatchModal(false);
          setUpdate(sys.uuid());
        }}
      />



    </div>
  );
};

Component.displayName = 'ProcessEditor';
export default Component;