import React, { useState, useRef } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Typography, Input, Form, Skeleton, Checkbox, Button, Tag, Dropdown, Modal, Tooltip, Space, Alert, Menu, Row, Slider, Col, Badge, Card, Spin, Pagination } from 'antd';
import ReactImageAnnotate from "react-image-annotate";
import {SaveOutlined, UndoOutlined, RedoOutlined } from '@ant-design/icons';

import { SearchOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { FilterFilled, ClearOutlined, SettingOutlined,EllipsisOutlined  } from '@ant-design/icons';

import { RightOutlined as NextIcon } from '@ant-design/icons';
import { LeftOutlined as PreviousIcon, SyncOutlined as RefreshIcon } from '@ant-design/icons';
import { ImMagicWand as InferIcon } from  'react-icons/im'

import { BsRSquareFill  as RenderIcon } from "react-icons/bs";


import BackButton from '../../components/AppHeader/BackButton'

import ImageViewer from './../../components/ImageViewer';

import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigation,
  useNavigate,
  useParams
} from "react-router-dom";
import { useStore } from '../../store';

import { BiExitFullscreen as ExitFullScreenIcon } from 'react-icons/bi'
import { BiFullscreen as FullScreenIcon } from 'react-icons/bi'

import Table from '../../components/Table';
import ImageModel from '../../models/Image'
import AnnotationModel from '../../models/Annotation'
import sys from '../../system'
const { Transaction } = sys.type;

import _ from 'lodash';

import PageHeader from '../../components/PageHeader'

import PredictForm from '../../forms/Predict'

import imageUtils from '../../utils/image'
import { getRangeSunTimes } from '../../utils/SunriseSunsetIO'

import './index.less';
import ImageTags from '../../components/ImageTags';
import ModelModel from '../../models/Model';

const Component = (props) => {

  const navigate = useNavigate();


  const params = useParams();
  const predictForm = useRef();
  const [predicting, setPredicting] = useState(false);
  const [rendering, setRendering] = useState(false);
  const [toBeSaved, setToBeSaved] = useState(false);
  const image = useRef();
  const [imagesIds, setImagesIds] = useState([]);
  const [index, setIndex] = useState();
  const [classes, setClasses] = useState();
  const [refresh, setRefresh] = useState();
  const [annotatorKey, setAnnotatorKey] = useState(sys.uuid())

  const [openPredictModal, setOpenPredictModal] = useState(false);

  useAsyncEffect(async () => {
    let classResult = await sys.orm("select distinct(name) from annotation where model_id is null and name not like 'SYNTH-%' order by name");
    setClasses(classResult.map(c => c.name));

    const imageIds = params.imageId.split(',');
    setImagesIds(imageIds);
    setIndex(0);
  }, [])


  useAsyncEffect(async () => {
    if (index == null) {
      return;
    }   
    image.current = await ImageModel.get(imagesIds[index]);
    if (image.current == null) {
      return;
    }
    image.current.reactImageAnnotateRegions = await imageUtils.toReactImageAnnotations(await image.current.annotations);
    image.current.url = sys.image.url(image.current.src);
    await sys.image.preload(image.current.url);
    setToBeSaved(false);
    setAnnotatorKey(sys.uuid())
  }, [refresh, index]);

  const onRefresh = async () => {
    let classResult = await sys.orm("select distinct(name) from annotation where model_id is null and name not like 'SYNTH-%' order by name");
    setClasses(classResult.map(c => c.name));

    const imageIds = params.imageId.split(',');
    setImagesIds(imageIds);
    setToBeSaved(false);
    setRefresh(sys.uuid());
  }

  const onRender = async () => {
    setRendering(true)
    setOpenPredictModal(false)
    let predictFormState;
    if (predictForm.current != null) {
      await predictForm.current.saveState();
      predictFormState = await predictForm.current.getState();
    } else {
      predictFormState = localStorage.getItem('image_predict_state');
      if (predictFormState != null) {
        try {
          predictFormState = JSON.parse(predictFormState);
        } catch (e) {
          console.log(e)
        }
      } 
    }

    if (predictFormState == null || predictFormState.workerId == null) {
      sys.notify({
        type: 'error',
        message: 'Worker required!'
      })
      setOpenPredictModal(true);
      return;
    }

    try {
      const response = await sys.api(`worker/${predictFormState.workerId}/render/${image.current.id}`);
      const img = await response.blob()
      image.current.url = URL.createObjectURL(img);
      setAnnotatorKey(sys.uuid())
      setToBeSaved(true);
    } catch (e) {
      sys.notify({
        type: 'error',
        message: 
          <div>
            <div>Error Rendering</div>
            <div>{e.message}</div>
          </div>
      });
      console.error(e)
    }
    setRendering(false)

  }

  const onPredict = async () => {
    setPredicting(true)
    setOpenPredictModal(false)
    try {
      let predictFormState;
      if (predictForm.current != null) {
        await predictForm.current.saveState();
        predictFormState = await predictForm.current.getState();
      } else {
        predictFormState = localStorage.getItem('image_predict_state');
        if (predictFormState != null) {
          try {
            predictFormState = JSON.parse(predictFormState);
          } catch (e) {
            console.log(e)
          }
        } 
      }
      
      if (predictFormState == null || predictFormState.modelId == null || predictFormState.workerId == null || predictFormState.confidence == null || predictFormState.area == null) {
        sys.notify({
          type: 'error',
          message: 'Required fields!'
        })
        setOpenPredictModal(true);
        return;
      }

      const model = await ModelModel.get(predictFormState.modelId)
      if (model == null || model.status != 'ACTIVE') {
        sys.notify({
          type: 'error',
          message: 'Required fields!'
        })
        setOpenPredictModal(true);
        return;
      }

      const response = await sys.api(`worker/${predictFormState.workerId}/predict/${image.current.id}/${predictFormState.modelId}`);
      if (response.ok) {
        let annotations = await response.json();
        annotations = annotations.filter(a => a.confidence >= (predictFormState.confidence / 100.0))
        if (predictFormState.classes.length > 0) {
          annotations = annotations.filter(a => predictFormState.classes.includes(a.name));
        }
        annotations.forEach(a => { // atribuiu um Id
          a.id = sys.uuid();
        })
        annotations = annotations.map(a => imageUtils.toReactImageAnnotation(a)) // converte para reactImageAnnotator
        if (image.current.reactImageAnnotateRegions.length > 0) { // filtra anotações repetidas
          annotations = annotations.filter(a => {
            return !image.current.reactImageAnnotateRegions.some(b => {
              if (a.cls != b.cls) {
                return false;
              }
              const interPercentage = imageUtils.regions.intersectionPercentage(a, b)
              return interPercentage > (predictFormState.area / 100);
            })
          });
        }

        if (annotations.length > 0) { // checka se há pelo menos uma anotação, senão manda warning
          image.current.reactImageAnnotateRegions = [...image.current.reactImageAnnotateRegions, ...annotations]
          setToBeSaved(true);
          setAnnotatorKey(sys.uuid())

          const distinctClasses = [...new Set(annotations.map(e => e.cls))]
          sys.notify({
            type: 'info',
            message:
              <div>
                <div>
                  {annotations.length} annotations added
                </div>
                <div>
                  {distinctClasses.map(t => <Tag key={'tc-'+t} color={imageUtils.stringToColor(t)}>{t}</Tag>)}
                </div>
              </div>
          });
        } else {
          sys.notify({
            type: 'warning',
            message: 'No annotations added'
          });
        }
      } else {
        throw response.status
      }
    } catch (e) {
      sys.notify({
        type: 'error',
        message: 
          <div>
            <div>Error Predicting</div>
            <div>{e.message}</div>
          </div>
      });
      console.error(e)
    } finally {
      setPredicting(false)
    }
  }

  const onSave = async () => {
    const oldAnnotations = await image.current.annotations;
    const newAnnotations = imageUtils.fromReactImageAnnotations(image.current.reactImageAnnotateRegions);

    const trx = new Transaction();
    trx.add(image.current);
    image.current.updated_at = new Date(); // atualizar o created_at

    oldAnnotations.forEach(a => {
        a.delete = true
        trx.add(a);
    });

    for (const annotation of newAnnotations) {
      const exists = oldAnnotations.find(a => a.id == annotation.id);
      if (exists != null) {
        exists.name = annotation.name;
        exists.points = annotation.points;
        exists.locked = annotation.locked;
        exists.visible = annotation.visible;
        exists.delete = false;
      } else {
        const a = AnnotationModel.create({
          id: annotation.id,
          image_id: image.current.id,
          type: annotation.type,
          name: annotation.name,
          locked: annotation.locked,
          visible: annotation.visible,
          points: annotation.points
        });
        trx.add(a);
        oldAnnotations.push(a)
      }
    }

    await trx.save();
    setToBeSaved(false);
  }



  return (
    <div style={{width: '100%', height: '100%'}}
      onKeyDown={(ev) =>{
        if (ev.key === "ArrowRight") {
          if (index >= imagesIds.length - 1) {
            return;
          }
          setIndex(index + 1);
          return;
        }
        if (ev.key === "ArrowLeft") {
          if (index <= 0) {
            return;
          }
          setIndex(index - 1);
          return;
        }
        if (ev.ctrlKey && ev.key === 's') {
          onSave()
          ev.preventDefault();
          return;
        }
        if (ev.ctrlKey && ev.key === 'r') {
          onRefresh()
          ev.preventDefault();
          return;
        }
        if (ev.ctrlKey && ev.key === 'p') {
          onPredict()
          ev.preventDefault();
          return;
        }
        if (ev.ctrlKey && ev.key === 'e') {
          onRender()
          ev.preventDefault();
          return;
        }
      }}
    
    >
      <PageHeader>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', gap: 20}}>
          <BackButton/>

          <Tooltip title={<div>Refresh <kbd>CTRL + R</kbd></div>} placement='bottom'>
            <Button
              type='text'
              disabled={!toBeSaved}
              icon={<RefreshIcon />}
              onClick={onRefresh}
            />
          </Tooltip>
          <Tooltip title={<div>Save <kbd>CTRL + S</kbd></div>} placement='bottom'>
            <Button 
              type={toBeSaved ? 'default' : 'text' }
              disabled={!toBeSaved}
              danger={toBeSaved}
              icon={<SaveOutlined />} 
              onClick={onSave}
            />
          </Tooltip>

          <div>
            <Button.Group>
              <Tooltip title={<div>Predict <kbd>CTRL + P</kbd></div>} placement='bottom'>
                <Button 
                  type='text' 
                  icon={<InferIcon/>}
                  onClick={onPredict}
                  disabled={rendering || predicting}
                />
              </Tooltip>
              <Tooltip title={<div>Render <kbd>CTRL + R</kbd></div>} placement='bottom'>
                <Button 
                  type='text'
                  icon={<RenderIcon />} 
                  disabled={toBeSaved || rendering || predicting}
                  onClick={onRender}
                />
              </Tooltip>
              <Button
                type='text' 
                icon={<EllipsisOutlined />}
                disabled={rendering || predicting}
                onClick={async () => {
                  setOpenPredictModal(true);
                }}
              />
            </Button.Group>
          </div>

          <Typography.Title style={{margin: 0, whiteSpace: 'nowrap'}} level={5}>Annotating image</Typography.Title>
          
          <div style={{
            display:'flex',
            flex: 'auto',
            textAlign: 'end',
          }}>
            <ImageTags id={image.current?.id}></ImageTags>
          </div>

          <div
            style={{
              display: 'flex',
              flex: 'auto',
              justifyContent: 'flex-end'
            }}
          >
          {
            imagesIds.length > 1 
            ? <Pagination
                pageSize={1}
                responsive={false}
                showSizeChanger={false}
                simple={false}
                showTitle={false}
                pageSizeOptions={[]}
                showQuickJumper={false}
                current={index+1}
                total={imagesIds.length}
                onChange={async (page, pageSize) => {
                  console.log('page',page)

                  if (toBeSaved === true) {
                    Modal.confirm({
                      closable:true,
                      title: 'Save changes?',
                      content: `Do you want to Save changes?`,
                      okText: "Save",
                      okType: 'primary',
                      onOk: async () => {
                        await onSave()
                        setIndex(page-1)
                      },
                      cancelText: "Discard",
                      onCancel: () => {
                        setIndex(page-1)
                      },
                    });
                  } else {
                    setIndex(page-1)
                  }
                }}
                itemRender={(page, type, originalElement) => {
                  let elem = originalElement
                  if (type === 'next') {
                    elem = <Tooltip title={<div>Next Image <kbd>→</kbd></div>}>
                            {originalElement}
                          </Tooltip>
                  } else if (type === 'prev') {
                    elem = <Tooltip title={<div>Previous Image<kbd>←</kbd></div>}>
                              {originalElement}
                            </Tooltip>
                  } else if (type === 'page') {
                    elem = <Tooltip title={'Image ' + page}>
                              {originalElement}
                           </Tooltip>
                  }
                  return <div style={{fontWeight: '600'}}>{elem}</div>;
                }}
              />
              : null
            }            
          </div>
          <div>
            <Tooltip title='Settings' placement='bottom'>
              <Button 
                type='text' 
                icon={<SettingOutlined />} 
                onClick={async () => {
                  // virtual click
                  const elem = document.querySelector('.MuiButtonBase-root.MuiButton-root.MuiButton-text:nth-child(4)');
                  await elem.click();
              }}/>
            </Tooltip>
          </div>
        </div>
      </PageHeader>
      <div className="annotator" key={annotatorKey}>
        { 
          image.current != null 
          ? <ReactImageAnnotate 
              // https://www.npmjs.com/package/react-image-annotate
              annotationType='image'
              regionClsList={classes}
              enabledTools={['create-box', 'create-point', 'create-line', 'create-polygon']}
              selectedImage={image.current.url}
              hideClone={true}
              hidePrevous={true}
              onChange={(state) => {
                setToBeSaved(true);
                if (state && state.images[0] && state.images[0].regions) {
                  image.current.reactImageAnnotateRegions = state.images[0].regions
                }
              }}
              images={[{
                key: image.current.id,
                src: image.current.url,
                regions: image.current.reactImageAnnotateRegions
              }]}
              />
            : null
          }
      </div>
      <Modal 
        title="Predict" 
        open={openPredictModal} 
        destroyOnClose={true}
        onOk={async () => { 
          predictForm.current
          await predictForm.current.saveState();
          setOpenPredictModal(false);
        }}
        width={'400px'}
        onCancel={() => { 
          setOpenPredictModal(false);
        }}
      >
        <PredictForm ref={predictForm}></PredictForm>
      </Modal>
    </div>
  );
};
export default Component;